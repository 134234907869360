import React from 'react';
import styled from '@emotion/styled';
import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import {
  HorizontalColumn,
  Divider,
} from 'components/publicWebsiteV2/util/pageCustomizationOrientation.styles';

// types
import type { WCmsEventView } from '@zola/svc-web-api-ts-client';

// context
import { useWebsiteThemeContext } from 'components/publicWebsiteV2/context';

// utils
import { formatDateUtc } from '@zola-helpers/client/dist/es/util/dateUtils';
import formatEventTime from 'components/publicWebsiteV2/util/formatEventTime';

// components
import { FoilAnimationWrapper } from 'components/publicWebsiteV2/common/FoilAnimation/FoilAnimationWrapper/FoilAnimationWrapper';
import { PageAnimationWrapper } from 'components/publicWebsiteV2/common/PageAnimationWrapper/PageAnimationWrapper';
import { FoilAnimationProvider } from 'components/publicWebsiteV2/common/FoilAnimation/FoilAnimationContext/FoilAnimationProvider';
import ScheduleAddress from './ScheduleAddress';
import AddToCalendarForm from './AddToCalendarForm';

// styles
import {
  Event,
  DesktopDate,
  DesktopName,
  Venue,
  ButtonContainer,
  sharedButtonStyles,
  AfterDate,
} from '../Schedule.styles';

type DesktopEventProps = {
  event: WCmsEventView;
  orientation: string;
  renderIcon: (event: WCmsEventView) => EmotionJSX.Element | null;
  shouldRenderDate: boolean;
  indexForAnimation: number;
};

const DesktopEvent: React.FC<DesktopEventProps> = ({
  event,
  orientation,
  renderIcon,
  shouldRenderDate,
  indexForAnimation,
}) => {
  const {
    state: {
      components: {
        styleCmsEntityHeaderFont,
        styleCmsEntityBodyFont,
        cmsEntityComponentBodyFontValues,
        ThemedButton,
      },
      wedding,
      inPreview,
    },
  } = useWebsiteThemeContext();

  const Para = styled.p<{ removeBottomMargin?: boolean }>`
    margin-bottom: ${({ removeBottomMargin, theme }) =>
      removeBottomMargin ? 0 : theme.SPACING.S24};
    white-space: pre-wrap;
  `;

  const handleClickMapBtn = () => {
    if (inPreview) return;
    if (typeof window !== 'undefined') {
      window.open(event.google_map_url, '_blank', 'noopener, noreferrer')?.focus();
    }
  };

  const handleClickVirtualEvent = () => {
    if (inPreview) return;
    if (typeof window !== 'undefined') {
      window.location.assign(`/wedding/${wedding?.slug}/virtual/${event.hash}`);
    }
  };
  const StyledDesktopDate = styleCmsEntityHeaderFont(DesktopDate);
  const StyledP = styleCmsEntityBodyFont(Para);
  const StyledEvent = styleCmsEntityBodyFont(Event);
  const StyledVenue = styleCmsEntityBodyFont(Venue);

  const dateDirection = (indexForAnimation + 1) % 2 ? 'left' : 'right';
  const direction = indexForAnimation % 2 ? 'left' : 'right';

  return (
    <StyledEvent shouldRenderDate={shouldRenderDate}>
      <FoilAnimationProvider>
        {shouldRenderDate && (
          // subtract one from index to ensure the date and the event have different indices
          <PageAnimationWrapper
            index={indexForAnimation - 1}
            animationProps={{
              direction: { wipeDirection: dateDirection, panDirection: dateDirection },
            }}
          >
            <StyledDesktopDate>
              <FoilAnimationWrapper
                text={formatDateUtc(event.start_at as string, 'dddd, MMMM D, YYYY')}
              />
            </StyledDesktopDate>
          </PageAnimationWrapper>
        )}
        <PageAnimationWrapper
          index={indexForAnimation}
          animationProps={{
            direction: { wipeDirection: direction, panDirection: direction },
          }}
        >
          <AfterDate orientation={orientation}>
            <HorizontalColumn orientation={orientation}>
              {renderIcon(event)}
              <DesktopName
                style={{
                  fontFamily: cmsEntityComponentBodyFontValues.fontFamily,
                  color: `#${cmsEntityComponentBodyFontValues.color}`,
                }}
              >
                {event.name}
              </DesktopName>
              <StyledP removeBottomMargin={orientation === 'HORIZONTAL'}>
                {formatEventTime(event.start_at as string, event.end_at)}
              </StyledP>
            </HorizontalColumn>
            {orientation === 'HORIZONTAL' && (
              <Divider style={{ backgroundColor: `#${cmsEntityComponentBodyFontValues.color}` }} />
            )}
            <HorizontalColumn orientation={orientation}>
              <StyledVenue orientation={orientation}>
                {event.venue_name}
                {event.venue_name && <br />}
                <ScheduleAddress event={event} />
              </StyledVenue>
              {event.attire && <StyledP>{event.attire}</StyledP>}
              {event.note && <StyledP>{event.note}</StyledP>}
              <ButtonContainer orientation={orientation}>
                {event.google_map_url && (
                  <ThemedButton css={sharedButtonStyles} onClick={handleClickMapBtn}>
                    Map
                  </ThemedButton>
                )}
                {event.start_at && <AddToCalendarForm event={event} />}
                {event.virtual_meeting?.meeting_id && (
                  // NOTE: will not route properly for admin preview and sample site
                  <ThemedButton css={sharedButtonStyles} onClick={handleClickVirtualEvent}>
                    Go to virtual event
                  </ThemedButton>
                )}
              </ButtonContainer>
            </HorizontalColumn>
          </AfterDate>
        </PageAnimationWrapper>
      </FoilAnimationProvider>
    </StyledEvent>
  );
};

export default DesktopEvent;
